import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import "../styles/dataPage.css"
import Cookies from 'js-cookie';
import url from '../url';
import {
  Editor,
  EditorProvider,
  BtnBold,
  BtnBulletList,
  BtnClearFormatting,
  BtnItalic,
  BtnLink,
  BtnNumberedList,
  BtnRedo,
  BtnStrikeThrough,
  BtnStyles,
  BtnUnderline,
  BtnUndo,
  HtmlButton,
  Separator,
  Toolbar,
  createButton,
} from 'react-simple-wysiwyg';
import Replace from '../assets/replace.png'

const PasswordPage = () => {

  const [gromadaInfo, setGromadaInfo] = useState({
    count_votes: 0,
    days_for_voting: 0,
    email: '',
    birthay_text: '',
    about: [{
      text: "",
      image: "",
      imageFiles: ""
    }]
  });
  const [saveText, setSaveText] = useState("Зберегти");
  const [saveText2, setSaveText2] = useState("Зберегти");
  const [saveText3, setSaveText3] = useState("Зберегти");
  const handleSave = async e => {
    e.preventDefault()
    try {
      await axios.post(url + "/gromada_info_params", gromadaInfo, {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      }
      );
      setSaveText("Збережено")
    } catch (err) {

    }
  }

  const handleClick = (e) => {
    console.log(e.target.name)
    window.location.reload(false);
    try {
      Cookies.set('admingromadia.in.uaDatabase', 'https://bilhorodiia.org:' + e.target.name);
      //window.location.reload(false);
    } catch (err) {
      console.log(err)
    }
  }

  const fecthGromadaInfo = async () => {
    try {
      const res = await axios.get(url + '/gromada_days', {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      })
      res.data[0].about = JSON.parse(res.data[0].about)
      setGromadaInfo(res.data[0])
      console.log(res.data[0])
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fecthGromadaInfo();
  }, [])

  const HandleChange = (e) => {
    setGromadaInfo(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const BtnAlignCenter = createButton('Link', '📞', ({ $selection }) => {
    if ($selection?.nodeName === 'A') {
      document.execCommand('unlink');
    } else {
      // eslint-disable-next-line no-alert
      document.execCommand('createLink', false, prompt('Телефон("tel:" НЕ ПРИБИРАТИ)', 'tel:') || undefined);
    }
  });

  function RemoveFromArray(arrayI, thisIndex) {
    const newArr = [];
    for (let i = 0; i < arrayI.length; i++) {
      if (i !== thisIndex)
        newArr.push(arrayI[i])
    }
    return newArr
  }



  const [selectedFileBirthday, setSelectedFileBirthday] = useState([]);
  const [selectedFileUrlBirthday, setSelectedFileUrlBirthday] = useState([]);
  const changeHandlerBirthday = (event) => {
    //console.log(...event.target.files)
    if (event.target.files.length > 0) {
      const urls = []
      for (let i = 0; i < event.target.files.length; i++) {
        urls[i] = URL.createObjectURL(event.target.files[i])
      }
      const NewFiles = [...event.target.files]
      setSelectedFileBirthday(NewFiles);
      setSelectedFileUrlBirthday(urls)
    }
  };


  function onChangebrithDay(e) {
    setGromadaInfo(prev => ({ ...prev, ["birthay_text"]: e.target.value }))
  }
  function onChangeAbout(e) {
    // Create a new object to avoid mutating the state directly
    let info = { ...gromadaInfo };

    // Update the nested property in the new object
    info.about[e.target.name] = {
      ...info.about[e.target.name],
      text: e.target.value,
    };

    // Update the state with the new object
    setGromadaInfo(info);
  }

  const handleSaveBirthday = async e => {
    e.preventDefault()
    try {
      await axios.post(url + "/gromada_info_birthday", { ...gromadaInfo, birthday: gromadaInfo.birthay_text }, {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      }
      ).then(UploadImagesBirthday());
      setSaveText2("Збережено")
    } catch (err) {

    }
  }

  const UploadImagesBirthday = async date => {
    if (selectedFileBirthday.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < 1; i++) {
        //formData.append('files', selectedFileBirthday[i]);
        formData.append("files", selectedFileBirthday[i], 'birthday.png')
      }
      try {
        let res = await axios.post(url + "/image", formData, {
          headers: {
            email: Cookies.get('admingromadia.in.email'),
            password: Cookies.get('admingromadia.in.password')
          }
        });
      } catch (err) {
        console.log(err)
      }
    }
  }



  function addAbout(e) {
    if (gromadaInfo.about == null) {
      setGromadaInfo(prev => ({
        ...prev, ["about"]: [{
          text: "",
          image: "",
          imageFiles: [],
          imageUrl: []
        }]
      }))
    } else {
      let arr = gromadaInfo.about
      arr.push({
        text: "",
        image: "",
        imageFiles: [],
        imageUrl: []
      })

      setGromadaInfo(prev => ({
        ...prev, ["about"]: arr
      }))
    }
  }

  const changeHandler = (e) => {
    console.log(e.target.name)
    if (e.target.files.length > 0) {
      const urls = []
      for (let i = 0; i < e.target.files.length; i++) {
        urls[i] = URL.createObjectURL(e.target.files[i])
      }
      let info = { ...gromadaInfo };
      let NewFiles = null
      let NewFilesUlr = null
      if (info.about[e.target.name].imageFiles && info.about[e.target.name].imageUrl) {
        NewFiles = [...info.about[e.target.name].imageFiles.concat(...e.target.files)]
        NewFilesUlr = [...info.about[e.target.name].imageUrl.concat(urls)]
      }
      else {
        NewFiles = [...e.target.files]
        NewFilesUlr = [...urls]
      }


      info.about[e.target.name] = {
        ...info.about[e.target.name],
        imageFiles: NewFiles,
        imageUrl: NewFilesUlr
      };

      // Update the state with the new object
      console.log(info.about)
      setGromadaInfo(info);
    }
  };

  const Hendledelete = (event) => {
    console.log(event.target.name)
    // const indexx = selectedFileUrl.indexOf(event.target.name);
    // setSelectedFile(RemoveFromArray([...selectedFile], indexx));
    // setSelectedFileUrl(RemoveFromArray(selectedFileUrl, indexx));

    const info = { ...gromadaInfo };
    const data = info.about

    data.forEach(item => {
      // Find the index of the target URL in the imageUrl array
      let index = -1
      if(item.imageUrl)
        index = item.imageUrl.indexOf(event.target.name);
      
      if (index !== -1) {
        // Remove the URL at that index
        item.imageUrl.splice(index, 1);

        // Remove the corresponding imageFile at the same index
        item.imageFiles.splice(index, 1);
      }
    });
    setGromadaInfo(info);
  };

  const handleSaveAbout = async e => {
    e.preventDefault()
    try {
      let data = [...gromadaInfo.about]
      const formData = new FormData();
      let send = []
      for (let i = 0; i < data.length; i++) {
        send.push({ text: data[i].text, image: '' })

        if(data[i].imageFiles)
          for (let j = 0; j < data[i].imageFiles.length; j++) {
            formData.append("files", data[i].imageFiles[j], "about_" + (i + 1) + '_' + (j + 1) + '.png')
            send[i].image += (i + 1) + '_' + (j + 1) + ' '
          }
        else{
          send[i].image = data[i].image
        }



        let res = await axios.post(url + "/image", formData, {
          headers: {
            email: Cookies.get('admingromadia.in.email'),
            password: Cookies.get('admingromadia.in.password')
          }
        });



        await axios.post(url + "/gromada_info_about", { data: JSON.stringify(send) }, {
          headers: {
            email: Cookies.get('admingromadia.in.email'),
            password: Cookies.get('admingromadia.in.password')
          }
        });
        setSaveText3("Збережено")
      }
    } catch (err) {
      console.log(err)
    }
  }

  const CleanLine = (event) => {
    const info = { ...gromadaInfo };
    const data = info.about
    data[event.target.name].image = ''

    setGromadaInfo(info);

    // handleSaveAbout()
  };

  return (
    <div className='DataPage'>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'space-between' }}>
        {/* <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ display: 'flex', width: '100%', flexDirection: 'row-reverse' }}>
            <button onClick={handleClick} name={"8800"} className='AddEvent' style={{ backgroundColor: "#0F6C00", color: "#fff" }}>Боярка</button>
          </div>
          <div style={{ display: 'flex', width: '100%', flexDirection: 'row-reverse' }}>
            <button onClick={handleClick} name={"8801"} className='AddEvent' style={{ backgroundColor: "#0F6C00", color: "#fff" }}>Ірпінь</button>
          </div>
          <div style={{ display: 'flex', width: '100%', flexDirection: 'row-reverse' }}>
            <button onClick={handleClick} name={"8802"} className='AddEvent' style={{ backgroundColor: "#0F6C00", color: "#fff" }}>Вишневе</button>
          </div>
          <div style={{ display: 'flex', width: '100%', flexDirection: 'row-reverse' }}>
            <button onClick={handleClick} name={"8803"} className='AddEvent' style={{ backgroundColor: "#0F6C00", color: "#fff" }}>Гатне</button>
          </div>
          <div style={{ display: 'flex', width: '100%', flexDirection: 'row-reverse' }}>
            <button onClick={handleClick} name={"8804"} className='AddEvent' style={{ backgroundColor: "#0F6C00", color: "#fff" }}>Буча</button>
          </div>
        </div> */}

        <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
          <div className='ImputWrapper' style={{ flexDirection: "row", alignItems: 'center', justifyContent: 'space-between' }}>
            <h1 style={{ fontSize: 24 }}>Загальні налаштування</h1>
            <button onClick={handleSave} className='AddEvent' style={{ backgroundColor: "#0F6C00", color: "#fff" }}>{saveText}</button>
          </div>
          <div className='ImputWrapper' style={{ flexDirection: "row", alignItems: 'center' }}>Необхідна кількість голосів
            <input onChange={HandleChange} type='number' name="count_votes" value={gromadaInfo?.count_votes} style={{ width: "auto", margin: 0, marginLeft: 24 }}></input>
          </div>
          <div className='ImputWrapper' style={{ flexDirection: "row", alignItems: 'center' }}>Кількість днів голосування
            <input onChange={HandleChange} type='number' name="days_for_voting" value={gromadaInfo?.days_for_voting} style={{ width: "auto", margin: 0, marginLeft: 24 }}></input>
          </div>
          <div className='ImputWrapper' style={{ flexDirection: "row", alignItems: 'center' }}>Пошта
            <input onChange={HandleChange} name="email" value={gromadaInfo?.email} style={{ width: "auto", margin: 0, marginLeft: 24 }}></input>
          </div>
        </div>

        <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
          <div className='ImputWrapper' style={{ flexDirection: "row", alignItems: 'center', justifyContent: 'space-between' }}>
            <h1 style={{ fontSize: 24 }}>Привітання з днем народження</h1>
            <button onClick={handleSaveBirthday} className='AddEvent' style={{ backgroundColor: "#0F6C00", color: "#fff" }}>{saveText2}</button>
          </div>
          <div className='ImputWrapper' style={{}}>
            <div style={{ position: 'relative' }}>
              <img src={selectedFileUrlBirthday.length != 0 ? selectedFileUrlBirthday : url + '/imageGet/birthday'} style={{ height: 160, width: 284, backgroundColor: 'gray', marginBottom: 8 }} />

              <label htmlFor="file-upload_image_birthday" >
                <img src={Replace} style={{ height: 36, width: 36, position: 'absolute', bottom: 12, left: 238, zIndex: 10, backgroundColor: '#fff', borderRadius: 4, border: "1px solid black", padding: 2 }} />
              </label>
              <input id="file-upload_image_birthday" type="file" name="file" onChange={changeHandlerBirthday} multiple accept="image/png, image/gif, image/jpeg" />
            </div>
            <div className='Editor'>
              <EditorProvider>
                <Editor value={gromadaInfo.birthay_text} onChange={onChangebrithDay} >
                  <Toolbar>
                    <BtnUndo />
                    <BtnRedo />
                    <Separator />
                    <BtnBold />
                    <BtnItalic />
                    <BtnUnderline />
                    <BtnStrikeThrough />
                    {/* <Separator /> */}
                    {/* <BtnNumberedList />
                <BtnBulletList /> */}
                    <Separator />
                    <BtnLink />
                    {/* <BtnClearFormatting /> */}

                    <BtnAlignCenter />
                    <Separator />
                    <HtmlButton />
                  </Toolbar>
                </Editor>
              </EditorProvider>
            </div>
          </div>
        </div>

        <div className='ImputWrapper' style={{}}>
          <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
            <h1 style={{ fontSize: 24 }}>Про громаду</h1>
            <button onClick={handleSaveAbout} className='AddEvent' style={{ backgroundColor: "#0F6C00", color: "#fff" }}>{saveText3}</button>
          </div>
          {gromadaInfo.about?.map((item, index) => (
            <div style={{}}>
              <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
                {item.image.split(' ').length - 1 == 0 ? <>
                  <label htmlFor={"file-upload" + index} name={index} className="custom-file-upload">
                    +
                  </label>
                  <input id={"file-upload" + index} type="file" name={index} onChange={changeHandler} multiple accept="image/png, image/gif, image/jpeg" />
                </>:<button name={index}  onClick={CleanLine}>Очистити</button>
                }
                <div className="ImageDiv">
                  {item.image.split(' ').map(file => (
                    <>
                      {file != '' && <div key={file} className='ImageWrapper'>
                        <img src={url + '/imageGet/about_' + file} alt=""></img>
                      </div>}
                    </>

                  ))}

                  {item.imageUrl?.map(file => (
                    <div key={file} className='ImageWrapper'>
                      <img src={file} alt=""></img>
                      <button onClick={Hendledelete} name={file} className='DeleteImg'>  </button>
                    </div>
                  ))}
                </div>
              </div>
              <div className='Editor'>
                <EditorProvider>
                  <Editor value={item.text} onChange={onChangeAbout} name={index}>
                    <Toolbar>
                      <BtnUndo />
                      <BtnRedo />
                      <Separator />
                      <BtnBold />
                      <BtnItalic />
                      <BtnUnderline />
                      <BtnStrikeThrough />
                      {/* <Separator /> */}
                      {/* <BtnNumberedList />
                <BtnBulletList /> */}
                      <Separator />
                      <BtnLink />
                      {/* <BtnClearFormatting /> */}

                      <BtnAlignCenter />
                      <Separator />
                      <HtmlButton />
                    </Toolbar>
                  </Editor>
                </EditorProvider>
              </div>
              {/* <textarea onBlur={HandleChange} name="text" cols="40" rows="5"></textarea> */}
            </div>
          ))}

          <button onClick={addAbout} style={{ height: 32, fontSize: 24, marginTop: 13 }}>+</button>


        </div>
      </div>
    </div >
  )
}

export default PasswordPage
