import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import "../../styles/dataPage.css"
import Cookies from 'js-cookie';
import url from '../../url';

const Notifications = () => {
  const navigate = useNavigate();

  const page = 'o'
  const back = '/Podatki/PodatkiShow/'
  let notificationId = useLocation().pathname.split("/")[3]
  const [itemNum, setItemNum] = useState([])

  //setItemNum()
  let arrDe = []
  if (notificationId[0] !== 'r') {
    arrDe = notificationId.split`.`.map(x => +x)
  }


  const [dailyData, setDailyData] = useState([])


  const fecthAllnotifications = async () => {
    try {
      const res = await axios.get(url + '/daily/'+page, {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      })
      setDailyData(JSON.parse(res.data))
      setItemNum([...arrDe])
      //console.log(JSON.parse(res.data))
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    fecthAllnotifications()
  }, [])



  function DataList() {
    let item = dailyData
    for (let i = 0; i < itemNum.length; i++)
      item = item[itemNum[i]].description
    return item
  }

  function DataText() {
    let item = dailyData
    for (let i = 0; i < itemNum.length - 1; i++)
      item = item[itemNum[i]].description
    return item[itemNum[itemNum.length - 1]]
  }


  function PathName() {
    let item = dailyData
    let name = ['Податки']
    for (let i = 0; i < itemNum.length; i++) {
      name.push(item[itemNum[i]].title)
      item = item[itemNum[i]].description
    }
    return name
  }

  const handleDelete = async (id) => {
    try {
      let pa = itemNum.join('.')
      if (pa === '')
        pa = id
      else
        pa += '.' + id
      await axios.delete(url + "/dailyDelte/" + pa +page, {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      })
      fecthAllnotifications()
      //navigate(itemNum.length != 0 ? '/Daily/DailyShow/' + itemNum.join('.') : '/Daily/DailyShow/r')
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className='DataPage'>
      <div className='Path'>{PathName().map((name, index) => (
        <div key={index}><button className='PathButton' onClick={() => { setItemNum(itemNum.slice(0, index)); navigate(itemNum.slice(0, index).length !== 0 ? back + itemNum.slice(0, index).join('.') : back+'r') }}>{name} </button>{'/'}</div>
      ))}</div>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
        <button disabled={itemNum.length === 0} className='arrowback' onClick={() => { setItemNum(itemNum.slice(0, itemNum.length - 1)); navigate(itemNum.slice(0, itemNum.length - 1).length !== 0 ? back + itemNum.slice(0, itemNum.length - 1).join('.') : back+'r') }}></button>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Link style={itemNum.length === 0 ? { display: 'none' } : {}} to={'/DailyAddImage/' + itemNum.join('.')+page}>
            <div style={{ width: 190 }} className='AddEvent'>{DataText()?.text === "" ? "Додати зображення" : "Замінити зображення"}</div>
          </Link>
          <Link style={itemNum.length === 0 ? { display: 'none' } : {}} to={'/DailyUpdateText/' + itemNum.join('.')+page}>
            <div className='AddEvent'>{DataText()?.text === "" ? "Додати текст" : "Редагувати текст"}</div>
          </Link>
          <Link style={itemNum.length === 0 ? { display: 'none' } : {}} to={'/DailyUpdateCoordinates/' + itemNum.join('.')+page}>
            <div className='AddEvent'>{DataText()?.coordinates === "" ? "Додати місце знаходження" : "Редагувати місце знаходження"}</div>
          </Link>
          <Link to={itemNum.length !== 0 ? '/DailyAdd/' + itemNum.join('.')+page : '/DailyAdd/r'+page}>
            <div className='AddEvent'>Додати кнопку</div>
          </Link>
        </div>
      </div>

      {itemNum.length !== 0 ?
        <>
          <div style={{ marginBottom: 0 }} className='ImputWrapper'>Текст
            {DataText()?.text !== "" ?
              <p style={{ fontWeight: 400, margin: 12, marginLeft: 24 }}>{DataText()?.text.split("\n").map(function (item, idx) {
                return (
                  <span key={idx}>
                    {item}
                    <br />
                  </span>
                )
              })}</p>
              : <p style={{ fontWeight: 400, margin: 12, marginLeft: 24 }}>Немає тексту</p>}
          </div>
          <div className='ImputWrapper'>Фото
            {DataText()?.image !== "" && DataText()?.image.split(' ').length >1  ?
              
              <div className='ImageDiv' style={{ fontWeight: 400, margin: 12, marginLeft: 24 }}>
                 {DataText()?.image.split(' ').slice(1).map((data) => (
                <div key={data} className='ImageWrapper' style={{ fontWeight: 400, margin: 12, marginLeft: 24 }}>
                  <img src={url + '/imageGet/' + DataText().image.split(' ')[0] + '_' + data} />
                </div>
                ))}
              </div>
              : <div className='ImageWrapper' style={{ fontWeight: 400, margin: 12, marginLeft: 24 }}>Немає фото</div>}
          </div>
        </>
        : null}

      <table >
        <thead>
          <tr>
            <th>Заголовок</th>
            <th width="330">Дії</th>

          </tr>
        </thead>
        <tbody>
          {DataList()?.map((data, index) => (
            <tr key={data.title}>
              <th >{data.title}</th>
              <th>
                <div style={{ display: "flex", paddingLeft: 19 }}>
                  <Link to={itemNum.length !== 0 ? '/DailyUpdateTitle/' + itemNum.join('.') + '.' + index+page : '/DailyUpdateTitle/' + index +page}>
                    <div style={{ backgroundColor: '#D9D9D9' }} className='notificationsButtons'>Редагувати назву</div>
                  </Link>
                  <button style={{ width: 170 }} className='notificationsButtons' onClick={() => { setItemNum([...itemNum, index]); navigate(itemNum.length !== 0 ? back + itemNum.join('.') + '.' + index : back + index) }}>Редагувати вміст</button>
                  <button className='notificationsButtons' style={{ backgroundColor: "#710000", color: '#fff' }} onClick={() => { handleDelete(index) }}>Видалити</button>
                </div>
              </th>
            </tr>
          ))}
        </tbody>
      </table>
    </div >
  )
}

export default Notifications
