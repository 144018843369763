import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import url from '../../url';
import Cookies from 'js-cookie';
import {
  Editor,
  EditorProvider,
  BtnBold,
  BtnBulletList,
  BtnClearFormatting,
  BtnItalic,
  BtnLink,
  BtnNumberedList,
  BtnRedo,
  BtnStrikeThrough,
  BtnStyles,
  BtnUnderline,
  BtnUndo,
  HtmlButton,
  Separator,
  Toolbar,
  createButton,
} from 'react-simple-wysiwyg';



function NewsAdd() {
  const [notification, setNotification] = useState({
    title: "",
    text: "",
    image: "",
  });
  const HandleChange = (e) => {
    setNotification(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }
  const navigate = useNavigate();
  const handleClick = async e => {
    e.preventDefault()
    if (selectedFile.length > 0) {
      notification.image = ''
      for (let i = 0; i < selectedFile.length; i++)
        notification.image += ' ' + (i + 1)
      //console.log(notification)
      try {
        const res = await axios.post(url + "/newsAdd", {...notification, text: notification.text.replaceAll("<div>",'').replaceAll("</div>",'<br>').replaceAll("<br><br><br>",'<br><br>')}, {
          headers: {
            email: Cookies.get('admingromadia.in.email'),
            password: Cookies.get('admingromadia.in.password')
          }
        });

        if (typeof res.data === "number")
          UploadImages(res.data).then(() => { navigate('/NewsPodii') });
      } catch (err) {
        console.log(err)
        alert('Error message: ' + err);
      }
    } else {
      alert('Додайте хоча б одне фото!');
    }
  }

  const UploadImages = async date => {
    //console.log(selectedFile)
    const formData = new FormData();
    for (let i = 0; i < selectedFile.length; i++) {
      //formData.append('files', selectedFile[i]);
      formData.append("files", selectedFile[i], date + "_" + (i + 1) + '.png')
    }
    console.log(formData)
    try {
      let res = await axios.post(url + "/image", formData, {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      });
    } catch (err) {
      console.log(err)
    }
  }




  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedFileUrl, setSelectedFileUrl] = useState([]);
  const changeHandler = (event) => {
    //console.log(...event.target.files)
    if (event.target.files.length > 0) {
      const urls = []
      for (let i = 0; i < event.target.files.length; i++) {
        urls[i] = URL.createObjectURL(event.target.files[i])
      }
      const NewFiles = [...selectedFile.concat(...event.target.files)]
      setSelectedFile(NewFiles);
      setSelectedFileUrl(selectedFileUrl.concat(urls))
    }
  };

  function RemoveFromArray(arrayI, thisIndex) {
    const newArr = [];
    for (let i = 0; i < arrayI.length; i++) {
      if (i !== thisIndex)
        newArr.push(arrayI[i])
    }
    return newArr
  }

  const Hendledelete = (event) => {
    const indexx = selectedFileUrl.indexOf(event.target.name);
    setSelectedFile(RemoveFromArray([...selectedFile], indexx));
    setSelectedFileUrl(RemoveFromArray(selectedFileUrl, indexx));
  };

  const BtnAlignCenter = createButton('Link', '📞', ({ $selection }) => {
    if ($selection?.nodeName === 'A') {
      document.execCommand('unlink');
    } else {
      // eslint-disable-next-line no-alert
      document.execCommand('createLink', false, prompt('Телефон("tel:" НЕ ПРИБИРАТИ)', 'tel:') || undefined);
    }
  });

  function onChange(e) {
    setNotification(prev => ({ ...prev, ["text"]: e.target.value }))
  }

  return (
    <div className='DataPage'>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row-reverse' }}>
        <button onClick={handleClick} className='AddEvent' style={{ backgroundColor: "#0F6C00", color: "#fff" }}>Зберегти</button>
      </div>
      <div className='ImputWrapper'>Заголовок
        <input onBlur={HandleChange} name="title"></input>
      </div>
      <div className='ImputWrapper'>Текст новини
        <div className='Editor'>
          <EditorProvider>
            <Editor value={notification.text} onChange={onChange} >
              <Toolbar>
                <BtnUndo />
                <BtnRedo />
                <Separator />
                <BtnBold />
                <BtnItalic />
                <BtnUnderline />
                <BtnStrikeThrough />
                {/* <Separator /> */}
                {/* <BtnNumberedList />
                <BtnBulletList /> */}
                <Separator />
                <BtnLink />
                {/* <BtnClearFormatting /> */}

                <BtnAlignCenter />
                <Separator />
                <HtmlButton />
              </Toolbar>
            </Editor>
          </EditorProvider>
        </div>
        {/* <textarea onBlur={HandleChange} name="text" cols="40" rows="5"></textarea> */}
      </div>
      <div className='ImputWrapper' style={{ marginBottom: 14 }}>Фото
      </div>
      <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
        <label htmlFor="file-upload" className="custom-file-upload">
          +
        </label>
        <input id="file-upload" type="file" name="file" onChange={changeHandler} multiple accept="image/png, image/gif, image/jpeg" />
        <div className="ImageDiv">
          {selectedFileUrl?.map(file => (
            <div key={file} className='ImageWrapper'>
              <img src={file} alt=""></img>
              <button onClick={Hendledelete} name={file} className='DeleteImg'>  </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default NewsAdd
